import React from 'react'
import comingsoon from '../../assets/images/coming.png'

const ComingSoon = () => {
  return (
    <div className='text-center'>
      <img src={comingsoon} alt="" className='mx-auto'width='100%' />     
    </div>
  )
}

export default ComingSoon